import Box from '@mui/material/Box';
import type {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput, FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

interface ProjectsFormContactPersonProps<T extends FieldValues> {
  register: UseFormRegister<Partial<Project>>;
  formErrors: FieldErrorsImpl<DeepRequired<Project>>;
  project?: Project;
  control: Control<T, object>;
}

export const ProjectsFormContactPerson = <T extends FieldValues>({
  project,
  formErrors,
  register,
}: ProjectsFormContactPersonProps<T>) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  return (
    <Box>
      <FormSectionHeader title={t('pages.projects.form.header.contactPerson')} />
      <FormInput<Partial<Project>>
        defaultValue={project?.contactPerson?.name}
        disabled={!isFormEnabled}
        formFieldName="contactPerson.name"
        label={t('entities.projects.fields.contactPerson.name.label')}
        register={register}
      />
      <FormInput<Partial<Project>>
        defaultValue={project?.contactPerson?.email}
        disabled={!isFormEnabled}
        error={formErrors.contactPerson?.email}
        formFieldName="contactPerson.email"
        label={t('entities.projects.fields.contactPerson.email.label')}
        register={register}
      />
    </Box>
  );
};

import { type FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { getSelectValue } from '@components/FormElements';
import { useProjectCreateApi, type Project } from '@hooks/api/projects';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { DetailPageLayout } from '@pages/common';

import { mapPeriod, ProjectsForm } from '../ProjectsForm';
import type { ProjectOutletContext } from '../types';

export const ProjectsCreate: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const createProject = useProjectCreateApi();
  const { handleOnChangeApiExportMappingFile, uploadApiExportData } =
    useOutletContext<ProjectOutletContext>();

  const handleCreateProject = async (data: FieldValues) => {
    try {
      const response = await createProject({
        ...data,
        organizationId: getSelectValue(data.organizationId),
        networkProviderId: getSelectValue(data.networkProviderId),
        generalContractorId: getSelectValue(data.generalContractorId),
        period: mapPeriod(data.period),
      } as Project);

      await uploadApiExportData(response);

      showMessage({
        message: t('pages.projects.create.messages.createSuccess', { projectName: data.name }),
        type: 'success',
      });
      revalidateCacheForEntity('/projects');
      navigate('/projects');
    } catch (error) {
      showMessage({ message: (error as Error).toString(), type: 'error' });
    }
  };

  return (
    <DetailPageLayout title={t('pages.projects.create.title')}>
      <ProjectsForm
        onChangeApiExportMappingFile={handleOnChangeApiExportMappingFile}
        onSubmit={handleCreateProject}
      />
    </DetailPageLayout>
  );
};

import useSWR from 'swr';

import { type PaginatedResponse, useJsonFetch } from '@hooks/api/useJsonFetch';

import type { Group } from './types';

export const useGroupsApi = () => {
  const jsonFetch = useJsonFetch<PaginatedResponse<Group>>();
  const response = useSWR('/groups?size=1000', jsonFetch);

  return response;
};

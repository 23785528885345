import { type FC, useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { getSelectValue } from '@components/FormElements';
import { useUserUpdateApi } from '@hooks/api/users';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { PlatformUserType } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { isUserInDeepUpOrg } from '@utils/user';

import { DeepupUserCreateForm, UserForm } from '../UserForm';
import { useFormUserState } from '../useFormUserState';

export const UserManagementEditForm: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();

  const { user: currentUser } = useFormUserState();
  const { id } = useParams();
  const updateUser = useUserUpdateApi();

  const currentUserInDeepupOrg = useMemo(() => {
    return currentUser && isUserInDeepUpOrg(currentUser);
  }, [currentUser]);

  const handleGoBackToUsers = () => {
    navigate('/users');
  };

  const handleSubmitForm = (id?: string) => async (data: FieldValues) => {
    const isUserLoaded = currentUser && id;

    if (isUserLoaded) {
      const getUserTypeOnSubmit = () => {
        if (currentUser.platformUserType === PlatformUserType.organizationMember && data.isAdmin)
          return PlatformUserType.organizationAdmin;
        if (currentUser.platformUserType === PlatformUserType.organizationAdmin && !data.isAdmin)
          return PlatformUserType.organizationMember;

        return currentUser.platformUserType;
      };

      const userTypeOnSubmit = getUserTypeOnSubmit();

      try {
        const response = await updateUser({
          ...data,
          id,
          email: undefined,
          password: data.password ?? undefined,
          organizationId: getSelectValue(data.organizationId),
          platformUserType: userTypeOnSubmit,
          version: currentUser.version,
          isInvoiceToolAdmin: data.isInvoiceToolAdmin,
        });

        showMessage({
          message: t('pages.user.edit.messages.updateSuccess', { userName: response.name }),
          type: 'success',
        });
        revalidateCacheForEntity('/users');
        handleGoBackToUsers();
      } catch (error) {
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    } else console.warn('no user given to update');
  };

  if (currentUserInDeepupOrg) return <DeepupUserCreateForm onSubmit={handleSubmitForm(id)} />;

  return <UserForm onSubmit={handleSubmitForm(id)} />;
};

import {
  ApiFilled,
  ApiOutline,
  BriefcaseFilled,
  BriefcaseOutline,
  FolderFilled,
  FolderOutline,
  PersonCrowdFilled,
  PersonCrowdOutline,
  PersonIdFilled,
  PersonIdOutline,
  ShieldPersonFilled,
  ShieldPersonOutline,
} from '@deepup/icons';
import { type Theme } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useRenderConfig } from '@hooks/ui';
import type { RenderConfig } from '@hooks/ui/useRenderConfig/types';
import { isBasePathname } from '@utils/pathname';

import { NavigationSidebarItem, type NavigationSidebarItemProps } from './NavigationSidebarItem';

export const NavigationSidebar: FC = () => {
  const { t } = useTranslation();
  const { isRouteEnabled } = useRenderConfig();
  const { pathname } = useLocation();

  const navigationSidebarItems: (NavigationSidebarItemProps & {
    sidebarItem: keyof RenderConfig['sidebar'];
  })[] = [
    {
      label: t('pages.organization.overview.title'),
      icon: BriefcaseOutline,
      iconSelected: BriefcaseFilled,
      to: '/organizations',
      sidebarItem: 'organizations',
      selected: isBasePathname(pathname, 'organizations'),
    },
    {
      label: t('pages.projects.overview.title'),
      icon: FolderOutline,
      iconSelected: FolderFilled,
      to: '/projects',
      sidebarItem: 'projects',
      selected: isBasePathname(pathname, 'projects'),
    },
    {
      label: t('pages.user.overview.title'),
      icon: PersonIdOutline,
      iconSelected: PersonIdFilled,
      to: '/users',
      sidebarItem: 'user',
      selected: isBasePathname(pathname, 'user'),
    },
    {
      label: t('pages.groupsPermissions.overview.title'),
      icon: ShieldPersonOutline,
      iconSelected: ShieldPersonFilled,
      to: '/groups',
      sidebarItem: 'groups',
      selected: isBasePathname(pathname, 'groups'),
    },
    {
      label: t('pages.groupsPermissions.deepup.title'),
      icon: PersonCrowdOutline,
      iconSelected: PersonCrowdFilled,
      to: '/deepup-groups',
      sidebarItem: 'deepupGroups',
      selected: isBasePathname(pathname, 'deepup-groups'),
    },
    {
      label: t('pages.apiAccesses.overview.title'),
      icon: ApiOutline,
      iconSelected: ApiFilled,
      to: '/api-accesses',
      sidebarItem: 'apiAccesses',
      selected: isBasePathname(pathname, 'api-accesses'),
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginX={4}
      minWidth={(theme: Theme) => theme.spacing(32)}
      paddingBottom={2}
      paddingTop={14}
      position="fixed"
    >
      <List>
        {navigationSidebarItems
          .filter(({ sidebarItem }) => isRouteEnabled(sidebarItem))
          .map(({ label, icon, to, disabled, selected, sidebarItem, iconSelected }) => (
            <NavigationSidebarItem
              data-testid={`sidebar-item-${sidebarItem}`}
              disabled={disabled}
              icon={icon}
              iconSelected={iconSelected}
              key={`${label}_${to}`}
              label={label}
              selected={selected}
              to={to}
            />
          ))}
      </List>
    </Box>
  );
};

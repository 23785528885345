import { Autocomplete, TextField } from '@mui/material';
import type { PropsWithChildren } from 'react';

export function ProjectsFilterSelect<T extends { id: string; name: string }>({
  onChange,
  defaultLabel,
  items,
  value,
}: PropsWithChildren<{
  onChange: (id: string) => void;
  items: T[];
  defaultLabel: string;
  value?: T | null;
}>) {
  return (
    <Autocomplete
      getOptionLabel={(p) => p.name}
      onChange={(_, value) => {
        if (value?.id) {
          onChange(value.id);
        } else {
          onChange('');
        }
      }}
      options={items}
      renderInput={(params) => <TextField {...params} placeholder={defaultLabel} />}
      value={value}
    />
  );
}

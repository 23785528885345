import { boolean, object, string } from 'yup';

export const organizationFormSchema = ({
  error,
}: {
  error: { name: string; dashboardSource: string; onlySpaces: string };
}) =>
  object({
    name: string().required(error.name).matches(/\S/, error.onlySpaces),
    abbreviation: string(),
    dashboardSource: string().url(error.dashboardSource),
    invoiceToolEnabled: boolean(),
  });

import Box from '@mui/material/Box';
import type { FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import type { FetchError } from '@hooks/api/customErrors';
import { useOrganizationUpdateApi } from '@hooks/api/organizations';
import { useHandleFetchError } from '@hooks/api/useHandleFetchError';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { OrganizationForm } from '../OrganizationForm';

import { useFormOrganizationState } from './useFormOrganizationState';

export const OrganizationsEditOverview: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const { id } = useParams();
  const updateOrganization = useOrganizationUpdateApi();
  const { organization } = useFormOrganizationState();
  const handleFetchError = useHandleFetchError();

  const handleGoBackToOrganizations = () => {
    navigate('/organizations?page=0&size=100');
  };

  const handleSubmitForm =
    (id: string) =>
    async ({ name, abbreviation, dashboardSource, invoiceToolEnabled }: FieldValues) => {
      if (organization) {
        try {
          await updateOrganization({
            id,
            name,
            abbreviation,
            dashboardSource,
            invoiceToolEnabled,
            version: organization?.version,
            isDeepUpOrg: organization.isDeepUpOrg,
            projectsCount: organization.projectsCount,
            usersCount: organization.usersCount,
          });

          revalidateCacheForEntity('/organizations');
          showMessage({
            message: t('pages.organization.edit.messages.updateSuccess', {
              organizationName: name,
            }),
            type: 'success',
          });
          handleGoBackToOrganizations();
        } catch (error) {
          handleFetchError(error as FetchError, 'organization');
        }
      }
    };

  if (!id || !organization) {
    return <Box>{t('pages.organization.form.info.noOrganizationGiven')}</Box>;
  }

  return <OrganizationForm onSubmit={handleSubmitForm(id)} organization={organization} />;
};

import type { GridSortModel } from '@mui/x-data-grid';

import { useApiWithOptions } from '../useApiWithOptions';
import { convertSortModelToString } from '../utils/convertSortModelToString';

import type { Project } from './types';

interface ProjectQueryOptions {
  page: number;
  size: number;
  sortModel?: GridSortModel;
  name?: string;
  networkProviderId?: string;
  generalContractorId?: string;
  organizationId?: string;
}

const constructQueryStringForProject = ({
  page,
  size,
  sortModel,
  name,
  networkProviderId,
  generalContractorId,
  organizationId,
}: ProjectQueryOptions): string => {
  const sortParam = convertSortModelToString(sortModel);
  const queryParams = {
    page: page.toString(),
    size: size.toString(),
    ...(name && { name }),
    ...(networkProviderId && { networkProviderId }),
    ...(generalContractorId && { generalContractorId }),
    ...(sortParam && { sort: sortParam }),
    ...(organizationId && { organizationId }),
  };

  const searchParams = new URLSearchParams(queryParams);

  return `?${searchParams.toString()}`;
};

export function useProjectsPaginatedApi(queryOptions: ProjectQueryOptions) {
  return useApiWithOptions<Project>({
    context: 'projects',
    queryString: constructQueryStringForProject(queryOptions),
  });
}

import { type FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { getSelectValue } from '@components/FormElements';
import { useProjectUpdateApi, type Project } from '@hooks/api/projects';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { useFormProjectState } from '@pages/Projects/useFormProjectState';

import { mapPeriod, ProjectsForm } from '../ProjectsForm';
import type { ProjectOutletContext } from '../types';

export const ProjectsEditOverview: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  const { id } = useParams();
  const updateProject = useProjectUpdateApi();
  const { project } = useFormProjectState();
  const { handleOnChangeApiExportMappingFile, uploadApiExportData } =
    useOutletContext<ProjectOutletContext>();

  const handleGoBackToProjects = () => {
    revalidateCacheForEntity('/projects');
    navigate('/projects');
  };

  const handleSubmitForm = async (data: FieldValues) => {
    if (project) {
      try {
        const response = await updateProject({
          ...(data as Project),
          networkProviderId: getSelectValue(data.networkProviderId),
          generalContractorId: getSelectValue(data.generalContractorId),
          period: mapPeriod(data.period),
          id: project.id,
          version: project.version,
          apiExport: data.apiExport,
        });

        await uploadApiExportData(response);

        showMessage({
          message: t('pages.projects.edit.messages.updateSuccess', { projectName: response.name }),
          type: 'success',
        });
        handleGoBackToProjects();
      } catch (error) {
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    } else {
      console.warn('no project given to update');
    }
  };

  if (!id || !project) {
    return null;
  }

  return (
    <ProjectsForm
      onChangeApiExportMappingFile={handleOnChangeApiExportMappingFile}
      onSubmit={handleSubmitForm}
    />
  );
};

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { FormSectionHeader } from '@components/FormElements';
import { useGroupsUserUpdateApi, type UserPermission } from '@hooks/api/groups';
import { useGroupsUserApi } from '@hooks/api/groups/useGroupsUserApi';
import { useOrganizationsApi } from '@hooks/api/organizations';
import { useUsersApi, type UserWithOrganization } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { getOrganizationById } from '@utils/organizations';
import { getUsersWithOrganization, filterUsersByOrganizationId } from '@utils/users';

import type { OutletContext } from '../types';
import { useFormGroupState } from '../useFormGroupState';

import { AllUsersTableContainer } from './AllUsersTable';
import { UsersInGroupTableContainer } from './UsersInGroupTable';

interface GroupsFormUserProps {
  isDeepUpInternal?: boolean;
  isEdit?: boolean;
}

export const GroupsFormUser: FC<GroupsFormUserProps> = ({
  isDeepUpInternal = false,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const { isItemEnabled } = useRenderConfig();
  const isFormEnabled = isItemEnabled('groups.edit.form.mode');
  const { data: usersData, error: usersError } = useUsersApi();
  const { data: organizationsData, error: organizationsError } = useOrganizationsApi();
  const { handleGoBack, handleGoNext } = useOutletContext<OutletContext>();
  const getUsersOfGroup = useGroupsUserApi();
  const updateGroupUser = useGroupsUserUpdateApi();
  const { group } = useFormGroupState();

  const [selectedUser, setSelectedUser] = useState<UserPermission[]>([]);
  const [error, setError] = useState<string>();
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

  const handleSubmitAndNext = async () => {
    if (selectedUser.length === 0)
      return setError(t('pages.groupsPermissions.create.messages.errorAddOneUser'));

    if (group?.id) {
      try {
        await updateGroupUser({ id: group?.id, userPermissions: [...selectedUser] });
        showMessage({
          message: t(
            isEdit
              ? 'pages.groupsPermissions.edit.messages.successUpdateUsers'
              : 'pages.groupsPermissions.create.messages.successSaveUsers',
          ),
          type: 'success',
        });

        handleGoNext(group);
      } catch (error) {
        console.warn('response of updateGroupUser', error);
        showMessage({
          message: t('pages.groupsPermissions.create.messages.errorSaveOverview', {
            errorMessage: error,
          }),
          type: 'error',
        });
      }
    }
  };

  const handleUpdateUserPermissionConfiguration = (userInGroup: UserPermission[]) => {
    setSelectedUser(userInGroup);
  };

  const initUsersOfGroup = useCallback(async () => {
    if (group?.id) {
      try {
        const usersOfGroup = await getUsersOfGroup(group?.id);

        const usersOfGroupWithId = usersOfGroup.map((user) => ({
          ...user,
          id: user.userId,
        }));

        usersOfGroupWithId.sort((a, b) =>
          a.user?.name < b.user?.name ? -1 : a.user?.name > b.user?.name ? 1 : 0,
        );

        setSelectedUser(usersOfGroupWithId);
      } catch (error) {
        showMessage({ message: `${error}`, type: 'error' });
      }
    }
    setIsLoadingUsers(false);
  }, [getUsersOfGroup, group?.id, showMessage]);

  useEffect(() => {
    setError(undefined);
  }, [selectedUser]);

  useEffect(() => {
    initUsersOfGroup();
  }, [initUsersOfGroup, group?.id]);

  useEffect(() => {
    if (error) {
      showMessage({ message: error, type: 'error' });
      setError(undefined);
    }
  }, [error, showMessage, t]);

  if (!usersData || !organizationsData) return <Box>Waiting for data...</Box>;
  if (usersError || organizationsError) return <Box>Error while fetching organizations data</Box>;

  const selectedOrganization = getOrganizationById(
    organizationsData.content,
    group?.organizationId,
  );

  const usersWithOrganization: UserWithOrganization[] = getUsersWithOrganization(
    usersData.content,
    organizationsData.content,
  );

  const filteredUsers = isDeepUpInternal
    ? filterUsersByOrganizationId(usersWithOrganization, group?.organizationId)
    : usersWithOrganization;

  const userTableSectionHeaderText = isDeepUpInternal
    ? t('pages.groupsPermissions.create.components.header.usersOfOrga', {
        orgaName: selectedOrganization?.name,
      })
    : t('pages.groupsPermissions.create.components.header.allUsers');

  return (
    <Stack>
      <Stack marginBottom={4}>
        <FormSectionHeader
          id="all-users-section"
          title={t('pages.groupsPermissions.create.components.header.usersInGroup')}
        />
        <UsersInGroupTableContainer
          isLoading={isLoadingUsers}
          onChangeUserPermissionConfiguration={handleUpdateUserPermissionConfiguration}
          selectedUsers={selectedUser}
        />
      </Stack>
      <Stack gap={1}>
        <FormSectionHeader title={userTableSectionHeaderText} />
        <AllUsersTableContainer
          allOrganizations={organizationsData.content}
          allUser={filteredUsers}
          hideOrganizationSelect={isDeepUpInternal}
          isLoading={isLoadingUsers}
          onChangeUserPermissionConfiguration={handleUpdateUserPermissionConfiguration}
          selectedUser={selectedUser}
        />
      </Stack>
      <BottomFormNavigation
        backTitle={t('common.goBack')}
        hasNext={!isLoadingUsers && isFormEnabled}
        nextTitle={`${t('common.save')} & ${t('common.next')}`}
        onGoBack={handleGoBack}
        onGoNext={handleSubmitAndNext}
      />
    </Stack>
  );
};

import { useCallback } from 'react';

import type { Organization } from '@hooks/api/organizations';
import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';

export const useOrganizationUpdateApi = () => {
  const jsonFetch = useJsonFetch<Organization>();

  const fetcher = useCallback(
    ({ id, name, abbreviation, dashboardSource, invoiceToolEnabled, version }: Organization) =>
      jsonFetch(`/organizations/${id}`, {
        method: 'PATCH',

        body: buildBody({ name, abbreviation, dashboardSource, invoiceToolEnabled, version }),
      }),
    [jsonFetch],
  );

  return fetcher;
};

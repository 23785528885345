import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import type { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { Organization } from '@hooks/api/organizations';

import { ProjectsFilterSelect } from './ProjectsFilterSelect';

interface ProjectsFilterBarProps {
  onChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeNetworkOperatorId: (newValue: string) => void;
  onChangeGeneralContractorId: (newValue: string) => void;
  organizations: Organization[];
  searchName?: string;
  networkOperatorId?: string;
  generalContractorId?: string;
}

export const ProjectsFilterBar: FC<ProjectsFilterBarProps> = ({
  onChangeName,
  onChangeNetworkOperatorId,
  onChangeGeneralContractorId,
  organizations,
  searchName,
  networkOperatorId,
  generalContractorId,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={2} mb={4}>
      <Box flexBasis="50%">
        <TextField
          data-testid="search-field"
          fullWidth
          onChange={onChangeName}
          placeholder={t('pages.projects.overview.components.filter.name')}
          type="search"
          value={searchName}
        />
      </Box>
      <Box flex={1}>
        <ProjectsFilterSelect<Organization>
          defaultLabel={t('pages.projects.overview.components.filter.networkProvider')}
          items={organizations}
          onChange={onChangeNetworkOperatorId}
          value={organizations.find((org) => org.id === networkOperatorId) || null}
        />
      </Box>
      <Box flex={1}>
        <ProjectsFilterSelect<Organization>
          defaultLabel={t('pages.projects.overview.components.filter.generalContractor')}
          items={organizations}
          onChange={onChangeGeneralContractorId}
          value={organizations.find((org) => org.id === generalContractorId) || null}
        />
      </Box>
    </Box>
  );
};
